<template>
  <div>
    <ButtonGooglePlay />
    <ButtonAppStore />
  </div>
</template>

<script>
import ButtonGooglePlay from "./ButtonGooglePlay";
import ButtonAppStore from "./ButtonAppStore";

export default {
  name: "ButtonsMobileStores",
  components: { ButtonAppStore, ButtonGooglePlay }
}
</script>

<style scoped lang="scss">
div {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 560px) {
    flex-direction: column;
  }

  button {
    margin: 12px;
  }
}

</style>
