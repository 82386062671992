<template>
  <button @click="openGooglePlay">
    <img src="@/assets/images/button/googleplay.svg" alt="googleplay-btn">
  </button>
</template>

<script>
export default {
  name: "ButtonGooglePlay",
  methods: {
    openGooglePlay: () => window.open(process.env.VUE_APP_GOOGLE_PLAY_APP_URL)
  }
}
</script>

<style scoped>
button {
  cursor: pointer;
}
</style>
