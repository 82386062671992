<template>
  <div id="room-sharing-page" class="room-sharing-page">
    <div class="room-sharing-page-content">
      <div class="join-room page-section">
        <div class="join-room-body">
          <img src="@/assets/images/main/invited.png" alt="#">
          <div class="title">
            You’ve been invited
          </div>
          <div class="subtitle">
            You’re here because you click an invitation link to join a room on Camfrog.
          </div>
          <Button
            class="join-room-btn"
            :label="$t('join_room_btn')"
            @click="joinRoom"
          />
        </div>
      </div>
      <div class="platform-apps page-section">
        <div class="platform-apps-body">
          <div class="desktop">
            <div class="title">
              New to Camfrog?
            </div>
            <div class="subtitle">
              Easy get the software here:
            </div>
            <button type="button" class="install-app" @click="signUpAndInstall()">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 17V18.931C4 20.0737 4.92631 21 6.06897 21H17.931C19.0737 21 20 20.0737 20 18.931V17" stroke="#23993B"/>
                <path d="M12 3V16.125M12 16.125L16.5 11.625M12 16.125L7.5 11.625" stroke="#23993B"/>
              </svg>
              <span>{{$t('sign_up_and_install')}}</span>
            </button>
          </div>
          <div class="mobile">
            <div class="title">{{$t('available')}}</div>
            <ButtonsMobileStores class="mobile-buttons" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonsMobileStores from "@/main/components/buttons/ButtonsMobileStores";


export default {
  name: "RoomSharingPage",

  components: {
    ButtonsMobileStores
  },

  data() {
    return {
      deeplink: ''
    }
  },

  computed: {
    deeplinkType() {
      return 'camfrog://join_room/?name=';
    }
  },

  created() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('deeplink') && params.get('deeplink').startsWith(this.deeplinkType)) {
      this.deeplink = params.get('deeplink');
    } else {
      this.deeplink = this.deeplinkType + process.env.VUE_APP_MAIN_ROOM_NICK
    }
  },

  methods: {
    signUpAndInstall() {
      this.setDeepLinkCookie(this.deeplink)
      window.location = process.env.VUE_APP_WINDOWS_DOWNLOAD_URL
    },
    joinRoom() {
      window.location = this.deeplink
    },
    setDeepLinkCookie(link) {
      return document.cookie = `join_deeplink=${link}; max-age=3600; SameSite=lax; path=/; secure`;
    },
  }
}
</script>

<style scoped lang="scss">
.room-sharing-page {
  height: 100%;
  width: 100%;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    background: #fff;
  }
  &:after {
    content: "";
    position: absolute;
    background: #F6F6F6;
    width: 100%;
    height: 50%;
    bottom: 0;
  }
  &-content {
    height: 100%;
    max-width: 1240px;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    padding: 105px 15px;
    .page-section {
      width: 100%;
    }
    .join-room {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
      &-body {
        width: 545px;
        height: 430px;
        position: relative;
        padding: 177px 78px 52px;
        background: #FFFFFF;
        border: 1px solid #D7D7D7;
        border-radius: 5px;
        img {
          position: absolute;
          top: -40px;
          left: 50%;
          transform: translate(-50%);
        }
        .title {
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          text-align: center;
          color: #363636;
        }
        .subtitle {
          margin-bottom: 41px;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #565656;
        }
        .join-room-btn {
          width: 100%;
          height: 60px;
        }
      }
    }
    .platform-apps {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
      padding-left: 30px;
      &-body {
        height: 100%;
        max-height: 430px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        .desktop {
          .title {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #363636;
          }
          .subtitle {
            margin-bottom: 27px;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #565656;
          }
          .install-app {
            border: 1px solid #2CBF49;
            border-radius: 4px;
            height: 40px;
            width: 290px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s ease;
            &:hover {
              cursor: pointer;
              background-color: #2CBF49;
              svg {
                path {
                  stroke: #fff;
                }
              }
              span {
                color: #fff;
              }
            }
            span {
              margin-left: 7px;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #23993B;
            }
          }
        }
        .mobile {
          .title {
            margin-bottom: 15px;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: #363636;
          }
          .mobile-buttons {
            margin-left: -12px;
          }
        }

      }

    }
  }
  @media screen and (max-width: 1170px) {
    .room-sharing-page-content {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .page-section {
        width: auto;
        height: 50%;
      }
      .platform-apps {
        padding-left: unset;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .join-room-body {
      width: auto;
      height: auto;
    }
  }
  @media screen and (max-width: 586px) {
    .join-room-body {
      padding: 150px 30px 30px;
    }
    .platform-apps-body {
      text-align: center;
      .desktop {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .mobile {
        width: 100%;
      }
    }

  }
}
</style>
