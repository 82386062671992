<template>
  <button @click="openAppstore">
    <img src="@/assets/images/button/appstore.svg" alt="appstore-btn">
  </button>
</template>

<script>
export default {
  name: "ButtonAppStore",
  methods: {
    openAppstore: () => window.open(process.env.VUE_APP_APPSTORE_APP_URL)
  }
}
</script>

<style scoped>
button {
  cursor: pointer;
}
</style>
